<template>
  <div class="main_wrapper_app_content">
    <h1>Live</h1>
    <v-card>
      <v-card-title>Coming Soon</v-card-title>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Live",
  data() {
    return {
      data: "",
      configData: {
        id: null,
        data: [
          {
            type: "watch",
            statu: true,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  // sockets: {
  //   vuepanelget: (data) => {
  //     // code
  //   },
  // },
  mounted() {
    this.comments.find((el) =>
      el.comment.id == item.comment.id
        ? (item.subComment = true)
        : (item.subComment = false)
    );

    // this.sockets.subscribe("vuepanelget", (data) => {
    //   this.data = data;
    // });
    this.configData.id = this.$route.params.id;
    this.$socket.emit("vuepanel", this.configData);
  },
};
</script>

<style scoped>
.realtimeSignal {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-left: 10px;
  animation: signal 1s ease-in-out infinite;
}
@keyframes signal {
  0% {
    background: #fff;
  }
  30% {
    background: #2acf00;
  }
  70% {
    background: #2acf00;
  }
  100% {
    background: #fff;
  }
}
</style>
